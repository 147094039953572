import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import Chat from "./components/Chat";

import "./styles/App.scss";

function App() {

  return (
    <div className="App">
      <Header />
      <Main />
      <Footer />
      <div className="chat-container">
        <Chat />
      </div>
    </div>
  );
}

export default App;
