import React from "react";
import { Image } from "antd";
import Logo from "../assets/logo-header.png";
import Menu from '../assets/Menu-header.png';

const Header = () => {
  return (
    <header className="app-container app-header">
      <div className="elementor-row">
        <div className="logo-blk elementor-column">
          <Image className="logo" src={Logo} preview={false} alt="logo" />
        </div>
        <div className="menu-blk elementor-column">
          <Image className="menu" src={Menu} preview={false} alt="menu" />
        </div>
        <div className="emty-blk elementor-column"></div>
      </div>
    </header>
  );
};

export default Header;
