import React, { useEffect, useRef, useState } from "react";
import { Button, Image, Upload, message } from "antd";
import { LeftOutlined, CloseOutlined } from "@ant-design/icons";
import api from "../api";
import useNotifications from "../hooks/useNotifications";
import { MESSAGE_TYPE, ROLE } from "../utils/constant";
import { ReactComponent as Attachment } from "../assets/svg/attachment.svg";
import { ReactComponent as Att } from "../assets/svg/att.svg";
import { ReactComponent as Sent } from "../assets/svg/sent.svg";
import { ReactComponent as Emoji } from "../assets/svg/emoji.svg";
import { ReactComponent as FileIcon } from "../assets/svg/file.svg";
import { ReactComponent as OutChat } from "../assets/svg/out-chat.svg";
import { ReactComponent as ArrowIcon } from "../assets/svg/arrow.svg";
import OpenChat from "../assets/open.png";
import ChatBotIcon from "../assets/chatwoot_bot.png";

import logoFav from "../assets/logo-fav.png";
import TextArea from "antd/es/input/TextArea";
import EmojiPicker from "./EmojiPicker";
import { formatDate, groupMessagesByDate } from "../utils/common";
const BOT_MESSAGE =
  "Thân mến rất vui được phục vụ quý khách. Chúng tôi có thể giúp được gì cho quý khách?";

const Chat = () => {
  const { messages, setMessages, pushMessage, pushBotMessage } =
    useNotifications();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageValue, setMessageValue] = useState("");
  const [messageGroups, setMessageGroups] = useState([]);
  const [isHaveChatHisory, setIsHaveChatHisory] = useState(false);
  const [isShowChat, setIsShowChat] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [fileList, setFileList] = useState([]);
  const endOfMessagesRef = useRef(null);

  useEffect(() => {
    getDataMessage();
  }, []);

  useEffect(() => {
    scroolToBottom();
  }, [isShowChat, isOpen]);

  useEffect(() => {
    setMessageGroups(groupMessagesByDate(messages));
    scroolToBottom();
    if (messages.length === 1) {
      const newMessage = pushBotMessage(BOT_MESSAGE, MESSAGE_TYPE.TEXT);
      setMessages((prev) => [...prev, newMessage]);
    }
  }, [messages]);

  const scroolToBottom = () => {
    if (endOfMessagesRef.current) {
      setTimeout(() => {
        endOfMessagesRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 50);
    }
  };

  const getDataMessage = async () => {
    const { data } = await api.get(
      `/messages/all?deviceID=${localStorage.getItem("chatDeviceID")}`
    );
    setMessages(data.docs);
    if (data.docs.length > 0) {
      setIsHaveChatHisory(true);
      setIsShowChat(true);
    }
  };

  const sendMessageOnclick = async () => {
    if (messageValue) {
      const newMessage = pushMessage(messageValue, MESSAGE_TYPE.TEXT);
      setMessageValue("");
      setMessages((prev) => [...prev, newMessage]);
    }
  };

  const addEmoji = (emoji) => {
    setMessageValue(messageValue + emoji.native);
    //     setShowEmojiPicker(false);
  };

  const toggleChat = () => {
    setIsOpen((prev) => !prev);
  };

  const handleFileUpload = async ({ file, fileList }) => {
    try {
      if (file) {
        let formData = new FormData();
        formData.append("file", file);

        const { data } = await api.post("/upload", formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        const endpoint = data.secure_url;

        const newMessage = pushMessage(endpoint, MESSAGE_TYPE.URL);
        setMessages((prev) => [...prev, newMessage]);
        setFileList([]);
      }
    } catch (error) {
      message.error("Đã có lỗi trong quá trình tải file. Vui lòng thử lại!");
      console.log("Upload img error: ", error);
    } finally {
      setLoading(false);
    }
  };

  const checkIsImage = (url) => {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  };

  const getFileName = (url) => {
    // Tìm vị trí của dấu "-"
    const dashIndex = url.indexOf("-");
    // Lấy tên file từ dấu "-" trở đi
    const fileName = url.substring(dashIndex + 1);
    return fileName;
  };

  const downloadFile = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = getFileName(url);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={`chat-wrapper ${isOpen ? "open" : ""}`}>
      {isOpen ? (
        <div className="chat-button">
          <button
            className="chat-button-item woot-elements--right woot-widget-bubble woot--close"
            onClick={toggleChat}
          ></button>{" "}
        </div>
      ) : (
        <div className="chat-button">
          <Image
            className="open-chat"
            width={64}
            src={OpenChat}
            alt="open-chat"
            onClick={toggleChat}
            preview={false}
          />
        </div>
      )}

      {/* */}
      <div className={`chat-area ${isOpen ? "open" : ""}`}>
        {isShowChat ? (
          <>
            <div className="chat-area-message-header">
              <div className="desktop-view">
                <div className="chat-area-message-header-navigator">
                  <LeftOutlined
                    onClick={() => {
                      setIsShowChat(false);
                    }}
                  />
                </div>
                <div className="chat-area-message-header-logo">
                  <Image
                    width={32}
                    src={logoFav}
                    alt="app-logo"
                    preview={false}
                  />
                </div>
                <div className="chat-area-message-header-text">
                  <div className="font-medium text-base leading-4 flex items-center text-black-900">
                    <span className="mr-1">GHTK VN</span>{" "}
                    <div className="h-2 w-2 rounded-full bg-green-500"></div>
                  </div>
                  <p className="text-xs mt-1 leading-3 text-black-700">
                    Thường trả lời sau vài phút
                  </p>
                </div>
              </div>
              <div className="end-div-blk">
                <div className="out-chat" title="Kết thúc cuộc trò chuyện">
                  <OutChat
                    onClick={() => {
                      setIsShowChat(false);
                      setIsHaveChatHisory(false);
                      setIsOpen(false);
                      setMessages([]);
                      localStorage.removeItem("chatDeviceID");
                    }}
                  />
                </div>
                <div className="mobile-view">
                  <CloseOutlined onClick={toggleChat} />
                </div>
              </div>
            </div>
            <div className="flex flex-col flex-1 overflow-hidden rounded-b-lg bg-slate-25 dark:bg-slate-800 chat-area-message-body">
              <div className="flex flex-1 overflow-auto chat-area-message-body--message">
                {Object.keys(messageGroups).map((date, index) => (
                  <div key={index} className="message-group">
                    <div className="time-send">{formatDate(date)}</div>
                    {messageGroups[date].map((message, idx) => (
                      <div
                        key={idx}
                        className={
                          message.role === ROLE.USER
                            ? "user-message"
                            : "system-message"
                        }
                      >
                        {message.type === MESSAGE_TYPE.TEXT &&
                          message.role !== ROLE.USER && (
                            <div className="first-bot-block">
                              <div className="chat-bubble-bot-avatar">
                                <Image
                                  src={ChatBotIcon}
                                  preview={false}
                                  width={24}
                                />
                              </div>
                              <div className="chat-bubble">
                                {message.message}
                              </div>
                            </div>
                          )}
                        {message.type === MESSAGE_TYPE.TEXT &&
                          message.role === ROLE.USER && (
                            <div className="chat-bubble">{message.message}</div>
                          )}
                        {message.type === MESSAGE_TYPE.URL &&
                          message.role !== ROLE.USER && (
                            <div className="first-bot-block">
                              <div className="chat-bubble-bot-avatar">
                                <Image
                                  src={ChatBotIcon}
                                  preview={false}
                                  width={24}
                                />
                              </div>
                              <div className="chat-bubble">
                                {checkIsImage(message.message) ? (
                                  <Image
                                    src={message.message}
                                    preview={false}
                                  />
                                ) : (
                                  <div className="file-wraper">
                                    <div className="file-icon">
                                      <FileIcon />
                                    </div>
                                    <div className="file-info">
                                      <div className="file-name">
                                        {getFileName(message.message)}
                                      </div>
                                      <div
                                        className="file-download"
                                        onClick={() => {
                                          downloadFile(message.message);
                                        }}
                                      >
                                        Tải xuống
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        {message.type === MESSAGE_TYPE.URL &&
                          message.role === ROLE.USER && (
                            <div className="chat-bubble">
                              {checkIsImage(message.message) ? (
                                <Image src={message.message} preview={false} />
                              ) : (
                                <div className="file-wraper">
                                  <div className="file-icon">
                                    <FileIcon />
                                  </div>
                                  <div className="file-info">
                                    <div className="file-name">
                                      {getFileName(message.message)}
                                    </div>
                                    <div
                                      className="file-download"
                                      onClick={() => {
                                        downloadFile(message.message);
                                      }}
                                    >
                                      Tải xuống
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                    ))}
                    <div ref={endOfMessagesRef} />
                  </div>
                ))}
                {/* Reference for scrolling */}
              </div>
              <div className="relative z-50 mb-1 px-5 rounded-lg chat-area-message-body--input">
                <div className="chat-message--input is-focused shadow-sm bg-white">
                  <TextArea
                    placeholder="Gõ tin nhắn của bạn"
                    autoSize={{ minRows: 1, maxRows: 6 }}
                    value={messageValue}
                    onChange={(e) => setMessageValue(e.target.value)}
                    onPressEnter={(e) => {
                      if (e.shiftKey) return; // Allow new line with Shift+Enter
                      sendMessageOnclick();
                      e.preventDefault(); // Prevent default behavior (new line)
                    }}
                  />
                  <div className="button-wrap">
                    {!messageValue && (
                      <Upload
                        className="button-upload"
                        fileList={fileList}
                        onChange={handleFileUpload}
                        beforeUpload={() => false} // Prevent automatic upload
                      >
                        <Button icon={<Att />}></Button>
                      </Upload>
                    )}
                    {/* <input
                      id="file-upload"
                      type="file"
                      onChange={handleFileUploadD}
                      accept="image/*"
                    /> */}
                    <div className="emoji-picker-wrapper">
                      {showEmojiPicker && (
                        <EmojiPicker
                          onEmojiSelect={addEmoji}
                          callBackFn={() => {
                            setShowEmojiPicker(!showEmojiPicker);
                          }}
                        />
                      )}
                      <Button
                        onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                      >
                        <Emoji />
                      </Button>
                    </div>
                    {messageValue && (
                      <div className="button-send">
                        <Button onClick={sendMessageOnclick} icon={<Sent />} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="chat-area-header">
              <div className="chat-area-header--icon">
                <Image
                  width={48}
                  src={logoFav}
                  alt="app-logo"
                  preview={false}
                  className="chat-area-header--icon-item"
                />
              </div>
              <div className="chat-area-header--title">
                <h2>CHĂM SÓC KHÁCH HÀNG</h2>
              </div>
              <div className="chat-area-header--sub-title">
                <p>GIAO HÀNG TIẾT KIỆM</p>
              </div>
            </div>
            <div className="chat-area-content">
              <div className="chat-area-content--item">
                <div className="chat-area-content--item-box">
                  <div className="chat-area-content--item-box-first">
                    <div>
                      <p className="box-title">Chúng tôi đang trực tuyến</p>
                      <p className="box-sub-title">
                        Thường trả lời sau vài phút
                      </p>
                    </div>
                    <div>
                      <Image
                        width={33}
                        src={logoFav}
                        alt="app-logo"
                        preview={false}
                      />
                    </div>
                  </div>
                  <div className="chat-area-content--item-box-second">
                    <Button
                      className="chat-now"
                      onClick={() => {
                        setIsShowChat(true);
                      }}
                    >
                      {isHaveChatHisory
                        ? "Tiếp tục hội thoại"
                        : "Bắt đầu một cuộc trò chuyện"}{" "}
                      <ArrowIcon />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Chat;
