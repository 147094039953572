// src/components/EmojiPicker.js
import React from "react";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";

const EmojiPicker = ({ onEmojiSelect, callBackFn }) => {
  return (
    <Picker
      classNam="emoji-mart "
      data={data}
      onEmojiSelect={onEmojiSelect}
     //  onClickOutside={() => {
     //        callBackFn(true);
     //  }}
      perLine={8}
      maxFrequentRows={1}
    />
  );
};

export default EmojiPicker;
