import React from "react";
import { Button, Image } from "antd";
import section1 from "../assets/Banner.jpg";
import section2 from "../assets/map.png";
import section4 from "../assets/item4.png";
import section5 from "../assets/item5.jpg";
import item1 from "../assets/item1.png";
import item2 from "../assets/item2.png";
import item3 from "../assets/item3.png";

const Main = () => {
  return (
    <main className="app-container app-main">
      <section className="section1 mb-20">
        <Image
          className="section-img"
          src={section1}
          preview={false}
          alt="banner"
        />
      </section>
      <section className="section2 mb-20">
        <Image
          className="section-img"
          src={section2}
          preview={false}
          alt="banner"
        />
      </section>
      <section className="section3 mb-20">
        <div className="section3-item">
          <div className="section3-item-header">
            <Image
              className="section3-item-img"
              src={item1}
              preview={false}
              alt="item1"
            />
          </div>
          <div className="section3-item-content">
            <span className="item-title">Tiếp nhận đơn hàng</span>
            <span className="item-sub-title">
              Shop đăng nhập và đăng đơn hàng cho trung tâm điều vận GHTK qua hệ
              thống quản lý riêng.
            </span>
            <div className="section3-item-button">
              <Button>Bấm vào đây</Button>
            </div>
          </div>
        </div>
        <div className="section3-item">
          <div className="section3-item-header">
            <Image
              className="section3-item-img"
              src={item2}
              preview={false}
              alt="item1"
            />
          </div>
          <div className="section3-item-content">
            <span className="item-title">Lấy hàng</span>
            <span className="item-sub-title">
              Nhân viên GHTK qua địa chỉ shop để lấy hàng tận nơi
            </span>
            <div className="section3-item-button">
              <Button>Bấm vào đây</Button>
            </div>
          </div>
        </div>
        <div className="section3-item">
          <div className="section3-item-header">
            <Image
              className="section3-item-img"
              src={item3}
              preview={false}
              alt="item1"
            />
          </div>
          <div className="section3-item-content">
            <span className="item-title">Giao hàng</span>
            <span className="item-sub-title"></span>
            <div className="section3-item-button">
              <Button>Bấm vào đây</Button>
            </div>
          </div>
        </div>
      </section>
      <section className="section4 mb-20">
        <Image
          className="section-img"
          src={section4}
          preview={false}
          alt="banner"
        />
      </section>
      <section className="section5 mb-10">
        <Image
          className="section-img"
          src={section5}
          preview={false}
          alt="banner"
        />
      </section>
    </main>
  );
};

export default Main;
